<template>
  <div>
    <div>
      <h1
        class="header-text pb-3"
        ref="headerEditProfile"
        aria-labelledby="header for Survey Results page"
        data-cy="header-admin-reports-survey"
        autofocus
      >
        Survey Results
      </h1>
      <div class="flex">
        <div class="flex-auto">
          <div class="mb-2">Term</div>
          <select
            class="mb-2 w-1/2 rounded border-dark-gray py-1"
            v-model="term"
          >
            <option
              class="h-8"
              v-for="(term, key) in terms"
              :key="key"
              :value="term"
            >
              {{ term.name }}
            </option>
          </select>
        </div>
        <div class="text-right flex-auto py-6">
          <Button
            class=""
            text="Download Data"
            @click="downloadData('survey_results.csv', records)"
          />
        </div>
      </div>
    </div>
    <div class="box-style mb-6">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <dynamic-table
          :records="this.records"
          :fields="tableConfig"
          no-data-msg=""
          striped
          hover
        >
          <template #cell(mentor_survey_date)="{ item }">
            {{ this.FormatDate(item.mentor_survey_date) }}
          </template>
          <template #cell(student_survey_date)="{ item }">
            {{ this.FormatDate(item.student_survey_date) }}
          </template>
        </dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import { get } from "@/composables/httpUtil";
import { downloadData } from "@/composables/downloadUtils";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/reusable-components/base-level-components/Button";
import { FormatDate } from "@/composables/date_utils";

export default {
  name: "SurveyResults",
  components: { LoadingSpinner, DynamicTable, Button },
  data() {
    return {
      isLoading: true,
      records: [],
      term: null,
      terms: [],
      tableConfig: [
        {
          display: "Mentor Email",
          name: "mentor_email",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor First",
          name: "mentor_first",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Last",
          name: "mentor_last",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Survey Date",
          name: "mentor_survey_date",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Repair?",
          name: "mentor_repair",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Feedback Student",
          name: "mentor_student_feedback",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Feedback Program",
          name: "mentor_program_feedback",
          sortable: false,
          class: "",
        },
        {
          display: "Student First",
          name: "student_first",
          sortable: false,
          class: "",
        },
        {
          display: "Student Last",
          name: "student_last",
          sortable: false,
          class: "",
        },
        {
          display: "Student Email",
          name: "student_email",
          sortable: false,
          class: "",
        },
        {
          display: "Level",
          name: "student_level",
          sortable: false,
          class: "",
        },
        {
          display: "Student Repair?",
          name: "student_repair",
          sortable: false,
          class: "",
        },
        {
          display: "Student Feedback",
          name: "student_feedback",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Score",
          name: "student_rank",
          sortable: false,
          class: "",
        },
        {
          display: "Find Own Mentor",
          name: "student_own_mentor",
          sortable: false,
          class: "",
        },
        {
          display: "Student Survey Date",
          name: "student_survey_date",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.fetchLookupResource().then(
      get("admin/terms")
        .then((terms) => {
          this.terms = terms.data;
          this.term = this.lookup_current_term;
        })
        .finally(() => {
          this.$watch(
            (vm) => vm.term,
            () => {
              this.isLoading = true;
              this.getData();
            },
            { immediate: true }
          );
          this.isLoading = false;
        })
    );
  },
  methods: {
    downloadData,
    FormatDate,
    getData() {
      let url = `admin/reports/survey_results?term=${this.term.term_id}`;
      get(url)
        .then((results) => {
          this.records = results;
        })
        .catch((error) => {
          console.log("error in catch statement", error);
          this.records = [];
        })
        .finally(() => (this.isLoading = false));
    },
    ...mapActions(["fetchLookupResource"]),
  },
  computed: {
    ...mapGetters(["lookup_current_term"]),
  },
};
</script>

<style scoped></style>
